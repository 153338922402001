<template>
	<div class="dashboard" :class="{ 'menu-active': menuOpened }">
		<SystemNotifications/>
		<SideBarMenu/>
		<div class="dashboard__content">
			<div class="dashboard__content__header">
				<Header>
					<template v-if="availableRoles" v-slot:left>
						<div class="left" :style="editorModeSelectorStyles">
							<FormulateInput
								type="select"
								name="select"
								:options="availableRoles"
								:key="updateKey"
								v-model="roleMode"
							/>
						</div>
					</template>
				</Header>
				<PageHeader v-if="Object.keys(pageHeaderData).length > 1"/>
                <PageHeaderHistory 
                    v-else-if="isHistoryMode && pageHeaderData.history && Object.keys(pageHeaderData.history).length"
                />
			</div>
			<router-view name="dashboard" ref="dashboard-container"/>
            <router-view name="contracts-sign" :route-back="{ name: 'dashboard' }"/>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SideBarMenu from "@/components/Menu/SideBarMenu";
import Header from "@/components/Header/Header";
import PageHeaderHistory from "@/components/Header/PageHeaderHistory";
import PageHeader from "@/components/Header/PageHeader";
import SystemNotifications from '@/components/Helpers/SystemNotifications'

import { mapState, mapMutations, mapGetters } from "vuex";

export default {
	name: 'Dashboard',
	data() {
		return {
            updateKey: Date.now(),
		}
	},
	components: {
		SideBarMenu,
		Header,
		PageHeader,
        PageHeaderHistory,
		SystemNotifications
	},
	computed: {
		...mapState( 'helper', {
			menuOpened: 'menuOpened',
			pageHeaderData: 'pageHeaderData',
            admin_roles: 'admin_roles',
		} ),
        ...mapState( '_module', {
			editorMode: 'editorMode'
		} ),

		...mapGetters('helper', ['labels']),

		currentPage() {
			return this.$route.name;
		},
		availableRoles() {
            if(this.admin_roles && Array.isArray(this.admin_roles) && this.admin_roles.length > 1 && typeof this.admin_roles !== 'string') {
                let roles = {};
				
                this.admin_roles.forEach(role => {
					if (['author', 'reviewer'].includes(role)) {
						roles[role] = this.labels[role];
					}
                });

				if (!roles.length) {
					roles.author = this.labels.author;
				}
                return roles;
            }

            if (this.admin_roles && this.admin_roles === 'string') {
                this.setEditorMode(this.admin_roles);
            }

            if (this.admin_roles && Array.isArray(this.admin_roles) && this.admin_roles.length === 1) {
                this.setEditorMode(this.admin_roles[0]);
            }
            
			return null;
		},
        countChanges() {
			return this.$store.getters['_module/countContentChanges'];	
		},
        roleMode: {
            get() {
                if(this.editorMode) {
                    return this.editorMode;
                }
                
                this.setEditorMode(Object.keys(this.availableRoles)[0]);

				return Object.keys(this.availableRoles)[0];

                // return this.admin_roles[0];
            },
            set(newRole){
                let answer = true;
                if( !this.countChanges.saveBtnDisabled ){
                    answer = window.confirm( this.labels.do_you_really_want_to_leave );

                    if(answer) {
                        this.$refs['dashboard-container'].getModuleMt();
                    }
                }
                if(answer) this.setEditorMode(newRole);
                else this.updateKey = Date.now();
            }
        },
        isHistoryMode() {
            return this.editorMode === 'reviewer';
        },
		editorModeSelectorStyles() {
			if (this.currentPage === 'module-editor') {
				return {};
			}

			return {
				display: 'none'
			}
		}
	},
    methods: {
        ...mapMutations( '_module', {
            setEditorMode: 'setEditorMode'
        } ),
    },
}
</script>

<style lang="scss" scoped>
.dashboard {
	display: flex;
	max-width: 1920px;
	margin: 0 auto;

	&__content {
		flex: 1 1 auto;
		-webkit-transition-duration: 0.3s;
		-moz-transition-duration: 0.3s;
		-o-transition-duration: 0.3s;
		transition-duration: 0.3s;
		max-width: 100vw;
		width: calc( 100% - 310px );

		&__header {
			position: sticky;
			top: 0;
			z-index: 1000;
			-webkit-transition-duration: 0.3s;
			-moz-transition-duration: 0.3s;
			-o-transition-duration: 0.3s;
			transition-duration: 0.3s;
		}

		@media ( max-width: 767px ) {
			padding-bottom: 74px;
		}
	}
}
</style>

<template>
  <div class="page-header">
    <div class="page-header__title">
      <span class="pre-title">{{ pageHeaderData.history.preTitle }}: </span>
      <span class="title">{{ pageHeaderData.history.title }}</span>
    </div>

    <div class="page-header__version">
      <span class="title mr-3">{{ pageHeaderData.history.versionOf }}</span>
      <span class="date mr-3">{{ pageHeaderData.history.varsionDate }}</span>
      <span class="time">{{ pageHeaderData.history.varsionTime }}</span>
    </div>

    <div
      class="page-header__menu d-flex align-items-center flex-wrap justify-content-center"
    >
      <div class="page-header__links">
        <router-link
          v-for="(link, linkIndex) in pageHeaderData.history.linkList"
          :key="linkIndex"
          :to="link.link ? link.link : ''"
          :class="['btn-wrapper', link.class]"
        >
          <div class="btn-item" v-if="link.type === 'label'">
            {{ link.label }}
          </div>
        </router-link>
      </div>

      <div class="page-header__btns justify-content-center">
        <router-link
          v-for="(button, btnIndex) in pageHeaderData.history.btnList"
          :key="btnIndex"
          :to="button.link ? button.link : ''"
          :class="['btn-wrapper', button.class]"
        >
          <Button
            class="btn-item"
            v-if="button.type === 'button' && !button.hidden"
            :style="button.style"
            :variant="button.variant"
            :label="button.label"
            :disabled="button.disabled"
            @click="button.action ? button.action() : () => {}"
          />

          <div class="btn-item" v-if="button.type === 'icon' && !button.hidden">
            <font-awesome-icon
              :icon="button.icon"
              :style="button.style"
              @click="button.action ? button.action() : () => {}"
            />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";

import { mapState } from "vuex";

export default {
  name: "PageHeaderHistory",
  components: {
    Button,
  },
  computed: {
    ...mapState("helper", {
      pageHeaderData: "pageHeaderData",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid $light_gray;
  border-right: 1px solid $light_gray;
  background-color: $white;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 991.98px) {
    flex-direction: column;
  }

  &__title {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 55px;

    // .pre-title {}

    .title {
      color: $light_text;
    }
  }

  &__version {
    display: flex;
    .title {
      font-weight: 500;
    }
  }

  &__btns,
  &__links {
    display: flex;
    padding-top: 10px;
    padding-bottom: 5px;

    @media (max-width: 767.98px) {
      flex-wrap: wrap;
    }

    .btn-wrapper {
      text-decoration: none;
      color: $dark_text;
      padding-bottom: 10px;
    }

    .current {
      &.btn-type-label .btn-item {
        color: $purple;
        background-color: $background;
        border-radius: 3px;
      }

      &.btn-type-icon {
        color: $purple;
      }
    }

    .btn-type-label {
      padding: 0 4px;
      cursor: pointer;
      border-left: 1px solid $light_gray;

      &:first-child {
        border-left: none;
      }

      .btn-item {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 4px 12px;
        @include transition--ease-out;

        &:hover {
          color: $purple;
          background-color: $background;
          border-radius: 3px;
        }
      }
    }

    .btn-type-button {
      .btn-item {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 4px 12px;
        margin: 0 7px;
      }
    }

    .btn-type-icon {
      cursor: pointer;
      @include transition--ease-out;

      &:hover {
        opacity: 0.7;
      }

      .btn-item {
        font-size: 1.125rem;
        padding: 0 12px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &__links {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>

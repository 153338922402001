<template>
  <div
    :class="['alert', `alert-${notification.variant}`]"
    :show="dismissCountDown"
  >
    <div class="message">
      <svg
        v-if="notification.variant === 'success'"
        xmlns="http://www.w3.org/2000/svg"
        width="47"
        height="47"
        viewBox="0 0 47 47"
      >
        <g transform="translate(-1492 -49)">
          <rect
            width="47"
            height="47"
            rx="15"
            transform="translate(1492 49)"
            fill="#34ce96"
          />
          <circle
            cx="16.5"
            cy="16.5"
            r="16.5"
            transform="translate(1499 56)"
            :fill="`${colorsHtml.white}`"
          />
          <path
            d="M14.729,12.061l-9.17,9.17a.206.206,0,0,1-.292,0L.06,16.024a.207.207,0,0,1,0-.292l1.265-1.265a.207.207,0,0,1,.292,0l3.8,3.8L13.172,10.5a.207.207,0,0,1,.292,0l1.265,1.265A.206.206,0,0,1,14.729,12.061Z"
            transform="translate(1508.965 56.557)"
            fill="#34ce96"
          />
        </g>
      </svg>
      <svg
        v-else-if="notification.variant === 'warning'"
        xmlns="http://www.w3.org/2000/svg"
        width="47"
        height="47"
        viewBox="0 0 47 47"
      >
        <g transform="translate(-1492 -199)">
          <g transform="translate(0 150)">
            <rect
              width="47"
              height="47"
              rx="15"
              transform="translate(1492 49)"
              fill="#fdba36"
            />
          </g>
          <g transform="translate(1498.94 170.486)">
            <g transform="translate(0 38.365)">
              <path
                d="M32.836,63.45,18.3,39.347a2.03,2.03,0,0,0-3.476,0L.292,63.45A2.03,2.03,0,0,0,2.03,66.528H31.1a2.03,2.03,0,0,0,1.738-3.078ZM16.575,46.7a1.375,1.375,0,0,1,1.541,1.306c0,2.547-.3,6.207-.3,8.754,0,.664-.728.942-1.241.942-.685,0-1.263-.278-1.263-.942,0-2.547-.3-6.207-.3-8.754C15.012,47.174,15.7,46.7,16.575,46.7ZM16.6,62.476a1.648,1.648,0,1,1,1.627-1.648A1.673,1.673,0,0,1,16.6,62.476Z"
                transform="translate(0.06 -39.216)"
                :fill="`${colorsHtml.white}`"
              />
            </g>
          </g>
        </g>
      </svg>
      <svg
        v-else-if="notification.variant === 'danger'"
        xmlns="http://www.w3.org/2000/svg"
        width="47"
        height="47"
        viewBox="0 0 47 47"
      >
        <g transform="translate(-1492 -199)">
          <g transform="translate(0 150)">
            <rect
              width="47"
              height="47"
              rx="15"
              transform="translate(1492 49)"
              fill="#F25E35"
            />
          </g>
          <g transform="translate(1498.94 170.486)">
            <g transform="translate(0 38.365)">
              <path
                d="M32.836,63.45,18.3,39.347a2.03,2.03,0,0,0-3.476,0L.292,63.45A2.03,2.03,0,0,0,2.03,66.528H31.1a2.03,2.03,0,0,0,1.738-3.078ZM16.575,46.7a1.375,1.375,0,0,1,1.541,1.306c0,2.547-.3,6.207-.3,8.754,0,.664-.728.942-1.241.942-.685,0-1.263-.278-1.263-.942,0-2.547-.3-6.207-.3-8.754C15.012,47.174,15.7,46.7,16.575,46.7ZM16.6,62.476a1.648,1.648,0,1,1,1.627-1.648A1.673,1.673,0,0,1,16.6,62.476Z"
                transform="translate(0.06 -39.216)"
                :fill="`${colorsHtml.white}`"
              />
            </g>
          </g>
        </g>
      </svg>
      <p>
        {{ notification.msg }}
      </p>
    </div>

    <svg
      class="close-notification"
      @click="closeNotification()"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="none" />
      <path
        d="M10,20A10,10,0,0,1,2.926,2.926,10,10,0,0,1,17.074,17.074,9.936,9.936,0,0,1,10,20Zm0-8.59h0L13.59,15,15,13.59,11.41,10,15,6.41,13.59,5,10,8.59,6.41,5,5,6.41,8.59,10,5,13.59,6.41,15,10,11.411Z"
        transform="translate(2 2)"
      />
    </svg>

    <button
      class="mt-3 mr-3"
      :class="['btn', `btn-${button.variant}`]"
      v-for="(button, idx) in notification.buttons"
      v-bind:key="idx"
      @click="[button.fn(), (dismissCountDown = 0)]"
    >
      {{ button.title }}
    </button>

    <hr />

    <div class="dismiss-counter">
      {{ notification_dismiss_after }} {{ dismissCountDown }} {{ seconds }}...
    </div>
  </div>
</template>

<script>
import colorsHtml from "/colors.config.json";
export default {
  name: "SystemNotificationsItem",
  data: () => ({
    colorsHtml: colorsHtml,
    dismissCountDown: 0,
  }),
  props: {
    notification: {
      type: Object,
    },
    clearNotification: {
      type: Function,
    },
    dismissSecs: {
      type: Number,
      default: null,
    },
    notification_dismiss_after: {
      type: String,
      // default: 'This notification will dismiss after'
    },
    seconds: {
      type: String,
      // default: 'seconds'
    },
  },
  methods: {
    closeNotification() {
      this.dismissCountDown = 0;
      this.clearNotification();
    },
  },
  mounted() {
    this.notifications = this.getNotifications;

    if (this.dismissSecs !== null) {
      this.dismissCountDown = this.dismissSecs;
    } else {
      this.dismissCountDown = this.notification.variant === "success" ? 1 : 10;
    }

    let dismissCountDownFn = () => {
      this.dismissCountDown -= 1;
      if (this.dismissCountDown === 0) {
        this.clearNotification();
      }
      if (this.dismissCountDown < 0) {
        this.$destroy();
        this.$el.remove();
        clearInterval(dismissCountDownInterval);
      }
    };
    let dismissCountDownInterval = setInterval(dismissCountDownFn, 1000);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.message {
  padding-right: 20px;
  display: flex;

  svg {
    margin-right: 20px;
    width: 47px;
  }

  p {
    margin-bottom: 0px;
    flex: 1 0;
    word-break: break-word;
    font-weight: 400;
  }
}

// .dismiss-counter {
/*white-space: nowrap;*/
// }

.close-notification {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1rem;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.5;
  }
}

.alert {
  padding: 25px 35px;
  border-radius: 15px;
  border: 1px solid $black;
  background-color: $white;

  .message {
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.03em;
    text-align: left;
    color: #2c3e50;
  }

  .dismiss-counter {
    font-weight: normal;
    font-size: 0.938rem;
    letter-spacing: 0.03em;
    text-align: left;
    color: #2c3e50;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  svg.close-notification {
    fill: $white;
    opacity: 1;

    &:hover {
      opacity: 0.7;
    }
  }

  &.alert-success {
    border: 1px solid #34ce96;
    background-color: #eaf6ed;

    svg.close-notification {
      fill: #c4ebce;
    }

    .dismiss-counter {
      color: #34ce96;
    }
  }

  &.alert-warning {
    border: 1px solid #fdba36;
    background-color: #fef7ea;

    svg.close-notification {
      fill: #e9c37c;
    }

    .dismiss-counter {
      color: #fdba36;
    }
  }

  &.alert-danger {
    border: 1px solid #f25e35;
    background-color: #fcedea;

    svg.close-notification {
      fill: #f6dad5;
    }

    .dismiss-counter {
      color: #f25e35;
    }
  }

  &.alert-info {
    border: 1px solid #17a2b8;
    background-color: #d1ecf1;

    svg.close-notification {
      fill: #abdde5;
    }

    .dismiss-counter {
      color: #17a2b8;
    }
  }
}
</style>

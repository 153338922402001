<template>
	<div id="alerts">
		<div class="alerts-container" ref="container">
		</div>
	</div>
</template>

<script>
	import Vue from "vue";
	import SystemNotificationsItem from "@/components/Helpers/SystemNotificationsItem";

	import { mapGetters, mapMutations } from 'vuex';

	export default {
		name: "systemnotifications",
		data: () => ({
			instance: null,
			notifications: {},
			notification_dismiss_after: '',
			seconds: ''
		}),
		computed: {
			...mapGetters( 'helper',['labels']),
		},
		methods: {
			...mapMutations( 'notification', {
				clearNotification: 'clearNotification'
			} ),
			...mapGetters( 'notification', {
				getNotifications: 'getNotifications'
			} )
		},
		mounted() {
			this.notification_dismiss_after = this.labels.notification_dismiss_after;
			this.seconds = this.labels.seconds;
			this.notifications = this.getNotifications;
		},
		watch: {
			"$store.state.notification.notifications"( nv ) {
				this.notifications = nv;

				if ( Object.keys(nv).length !== 0 ) {
					let ComponentClass = Vue.extend( SystemNotificationsItem );
					this.instance = new ComponentClass( {
						propsData: {
							notification: this.notifications,
							clearNotification: this.clearNotification,
							dismissSecs: this.notifications.dismissSecs,
							notification_dismiss_after: this.notification_dismiss_after,
							seconds: this.seconds
						}
					} );
					this.instance.$mount();
					this.$refs.container.appendChild( this.instance.$el );
				} else if ( this.instance ) {
					this.instance.$destroy();
					this.$refs.container.removeChild( this.instance.$el );
					this.instance = null;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	#alerts {
		position: fixed;
		right: 15px;
		top: 60px;
		z-index: 10001;
		max-width: 500px;
		width: 100%;
	}

	.alerts-container
	{
		width: 500px;
		// display: flex;
		// flex-direction: column;
		// align-items: center;
		position: relative;

		::v-deep
		{
			.alert
			{
				width: 100%;
				// height: 145px;
				margin: 0 auto;
				transition: all .4s ease;
			}

			.alert:nth-child(2)
			{
				width: calc(100% - 20px);
				margin-top: -134px;
				z-index: 1;
			}

			.alert:nth-child(3),
			.alert:nth-child(n+3)
			{
				width: calc(100% - 40px);
				margin-top: -132px;
				z-index: 2;
			}

			.alert:nth-child(n+4)
			{
				height: 0;
				overflow: hidden;
				opacity: 0;
				pointer-events: none;
			}
		}

		&:hover
		{
			::v-deep
			{
				.alert:nth-child(2),
				.alert:nth-child(3),
				.alert:nth-child(n+3),
				.alert:nth-child(n+4)
				{
					width: 100%;
					margin-top: 10px;
					opacity: 1;
					pointer-events: unset;
					height: initial;
					transform: translateY(0);
				}
			}
		}
	}
</style>
